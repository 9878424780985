<template>
    <v-card flat>
        <v-card-text class="pb-0 mb-0">
            <v-row style="border-bottom: 2px solid #4E87DD;">
                <v-col style="color:#4E87DD; font: normal normal bold 18px/25px Apple SD Gothic Neo;">070번호 발급</v-col>
                <v-col style="color:#555555; font: normal normal 600 14px/25px Apple SD Gothic Neo;" class="text-right">추천번호 또는 희망번호 중 원하는 번호를 선택해주세요.</v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
            <v-row class="mt-3">
                <v-col cols="8" class="text-center">
                    <v-radio-group v-model="formData.svc_mdn_type" class="mt-0" row>
                        <v-radio
                            value="RECOMMEND"
                            class="mt-3"
                        >
                            <template v-slot:label>
                                <span style="font: normal normal normal 16px/25px Apple SD Gothic Neo;">추천번호</span>
                            </template>
                        </v-radio>
                        <v-radio
                            value="WISH"
                            class="mt-3"
                        >
                            <template v-slot:label>
                                <span style="font: normal normal normal 16px/25px Apple SD Gothic Neo;">희망번호</span>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-card>
                        <v-card-text v-if="formData.svc_mdn_type == 'RECOMMEND'">
                            <v-row align="center" justify="center">
                                <v-col cols="2" style="font: normal normal bold 21px/25px Apple SD Gothic Neo;" class="text-center">
                                    {{ txtSvcMdnType }}
                                </v-col>
                                <v-col cols="8" class="text-center pa-6" style="font: normal normal bold 25px/25px Apple SD Gothic Neo;" v-if="formData.svc_mdn_type == 'RECOMMEND'">
                                    {{ formData.recomm_svc_mdn | phoneNum }}
                                    <br/>
                                    <v-btn class="mt-8" color="green" width="120px" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo; opacity: 1;"
                                           @click="doSearchRecommSvcMdn"
                                           v-if="formData.svc_mdn_type == 'RECOMMEND'">새로고침
                                    </v-btn>
                                    <v-btn color="green" width="94px" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo; opacity: 0.5;" v-else>새로고침</v-btn>
                                </v-col>
                                <v-col></v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-text v-else>
                            <v-row align="center" justify="center">
                                <v-col cols="2" style="font: normal normal bold 21px/25px Apple SD Gothic Neo;" class="text-center ">
                                    {{ txtSvcMdnType }}
                                </v-col>
                                <v-col cols="8" class="text-right pa-6 mt-2" style="font: normal normal bold 21px/25px Apple SD Gothic Neo;">
                                    <v-text-field
                                        v-model="formData.wish_svc_mdn"
                                        :error="formStatus['wish_svc_mdn'].err"
                                        outlined
                                        dense
                                        hide-details
                                        placeholder="전화번호 끝 4자리"
                                        class="mt-n2 pl-10 pr-10"
                                        prepend-inner-icon="mdi-magnify"
                                        ref="wish_svc_mdn"
                                    >
                                        <template v-slot:append v-if="formStatus['wish_svc_mdn'].err_msg">
                                            <div style="color:#FF4D4D; font-size:13px; padding-top:5px;">
                                                {{ formStatus['wish_svc_mdn'].err_msg }}
                                            </div>
                                        </template>
                                    </v-text-field>
                                </v-col>
                                <v-col cols="1" class="text-left ml-n10">
                                    <v-btn color="green" width="120px" dark elevation="0" style="font: normal normal 600 16px/25px Apple SD Gothic Neo;" @click="doSearchWishSvcMdn">검색</v-btn>
                                </v-col>
                                <v-col></v-col>
                            </v-row>
                            <v-row class="mt-n5" v-if="searchWishSvcMdnFlag || (formData.wish_svc_mdn_list && formData.wish_svc_mdn_list.length != 0)">
                                <v-col class="text-center">
                                    <template v-if="!searchWishSvcMdnFlag">
                                        <v-radio-group v-model="selectedWishSvcMdn" v-if="formData.wish_svc_mdn_list">
                                            <template v-for="(svcMdn, idx) in formData.wish_svc_mdn_list">
                                                <v-radio style="display: inline-block;" class="mt-1 text-center" :value="svcMdn" :label="svcMdn | phoneNum"></v-radio>
                                            </template>
                                        </v-radio-group>
                                    </template>
                                    <template v-else>
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                            class="mt-5"
                                            style="height: 207px;"
                                        ></v-progress-circular>
                                    </template>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="mt-8 mb-n10">
            <v-row>
                <v-col>
                    <v-icon x-large class="mr-3">mdi-message-alert</v-icon>
                    <span style="font: normal normal bold 21px/25px Apple SD Gothic Neo;">기존 번호를 사용하고 싶으시나요?</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-alert
                        outlined
                        text
                        color="primary"
                        class="mt-n3"
                    >
                        <div class="ml-5" style="font: normal normal normal 16px/25px Apple SD Gothic Neo;">기존 번호의 통신사에서 제공하는 착신전환 서비스에 가입 후 위 070번호를 착신번호로 등록하시면 됩니다.</div>
                        <div class="ml-5" style="font: normal normal normal 16px/25px Apple SD Gothic Neo;">(단, 통화연결 시 기존 통신사에 발신통화료가 발생합니다.)</div>
                    </v-alert>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="text-center mt-10">
            <v-btn color="grey" width="200px" height="55px" dark elevation="0" style="font: normal normal bold 20px/37px Apple SD Gothic Neo; margin-right:5px;" @click="goPrev">이전</v-btn>
            <v-btn color="#4E87DD" width="200px" height="55px" dark elevation="0" style="font: normal normal bold 20px/37px Apple SD Gothic Neo; opacity: 0.5" @click="doNext" v-if="btnInActiveNext">다음
            </v-btn>
            <v-btn color="#4E87DD" width="200px" height="55px" dark elevation="0" style="font: normal normal bold 20px/37px Apple SD Gothic Neo; opacity: 1" @click="doNext" v-else>다음</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>

import {mapGetters} from 'vuex'
import joinApi from '@/api/join.js'

export default {
    name: 'join_step_03',
    data: () => ({
        formData: {
            join_tid: '',
            svc_mdn_type: 'RECOMMEND',
            recomm_svc_mdn: '',
            wish_svc_mdn: '',
            req_svc_mdn: '',
            wish_svc_mdn_list: []
        },
        formStatus: {
            'wish_svc_mdn': {
                err: false,
                err_msg: ''
            },
        },
        recommExcludeSvcMdnList: [],
        wishExcludeMdnList: [],
        selectedWishSvcMdn: '',
        searchRecommSvcMdnFlag: false,
        searchWishSvcMdnFlag: false,
        searchedWishSvcMdn: '',
        excludeLimitCount: 50
    }),
    computed: {
        ...mapGetters({
            joinTid: 'join/getJoinTid',
            joinFormStep02: 'join/getJoinFormStep02',
            joinFormStep03: 'join/getJoinFormStep03'
        }),
        btnInActiveNext() {
            return (this.formData.svc_mdn_type == 'WISH' && this.selectedWishSvcMdn == '') || (this.formData.svc_mdn_type == 'RECOMMEND' && !this.formData.recomm_svc_mdn)
        },
        txtSvcMdnType() {
            return this.formData.svc_mdn_type == 'RECOMMEND' ? '추천번호' : '희망번호'
        }
    },
    created() {
    },
    mounted() {
        this.$store.commit('join/setJoinStep', 3)

        let paramJoinTid = this.$route.params.join_tid

        if (!this.joinFormStep02 || Object.keys(this.joinFormStep02).length == 0 || !this.joinTid || paramJoinTid != this.joinTid) {
            this.$store.dispatch('openAlert', {
                'message': '잘못된 접근입니다',
                closeCb: (va) => {
                    va.$router.push('/join/step01')
                },
                va: this
            })
        } else if (this.joinFormStep03 && Object.keys(this.joinFormStep03).length != 0) {
            this.formData = Object.assign({}, this.joinFormStep03)

            if (this.formData.svc_mdn_type == 'WISH') {
                this.selectedWishSvcMdn = this.formData.req_svc_mdn
                this.searchedWishSvcMdn = this.formData.wish_svc_mdn
            }
        } else {
            this.formData.join_tid = this.joinTid
            this.doSearchRecommSvcMdn()
        }
    },
    methods: {
        goPrev() {
            this.$router.push(`/join/step02/${this.joinTid}`)

            /*this.$store.dispatch('openConfirm', {
                'message': '이전 페이지로 돌아가시겠습니까?',
                'okCb': (va) => {
                    va.$router.push(`/join/step02/${va.joinTid}`)
                }, va: this
            })*/
        },
        doNext() {
            if (this.formData.svc_mdn_type == 'WISH' && this.selectedWishSvcMdn == '') {
                this.$store.dispatch('openAlert', {'message': '희망번호를 선택해 주세요'})
                return
            }

            let reqSvcMdn = this.formData.svc_mdn_type == 'RECOMMEND' ? this.formData.recomm_svc_mdn : this.selectedWishSvcMdn
            this.formData.req_svc_mdn = reqSvcMdn

            this.$store.commit('join/setJoinFormStep03', this.formData)
            joinApi.submitSvcMdn(this.formData, (va) => {
                    va.$router.push(`/join/step04/${va.joinTid}`)
                },
                (err, va) => {
                    let msg = '처리 중 오류가 발생하였습니다.'

                    if (err == 'NO_TRAN') {
                        msg = '인증시간이 만료되었습니다. 다시 인증해주세요.'
                    } else if (err == 'UNUSABLE_SVC_MDN') {
                        msg = '사용할 수 없는 번호입니다.'
                    }

                    va.$store.dispatch('openAlert', {
                        'message': msg,
                        'va': va,
                        'closeCb': (va) => {
                            if (err == 'NO_TRAN') {
                                va.$router.push('/join/step01')
                            }
                        }
                    })
                }, this)

            /*this.$store.dispatch('openConfirm', {
                'message': `${this.$options.filters.phoneNum(reqSvcMdn)} 번호 (으)로 진행하시겠습니까?`,
                'okCb': (va) => {
                    va.$store.commit('join/setJoinFormStep03', ctx.formData)
                    joinApi.submitSvcMdn(va.formData, (va) => {
                        va.$router.push(`/join/step04/${va.joinTid}`)
                    },
                    (err, va) => {
                        let msg = '처리 중 오류가 발생하였습니다.'

                        if (err == 'NO_TRAN') {
                            msg = '인증시간이 만료되었습니다. 다시 인증해주세요.'
                        } else if (err == 'UNUSABLE_SVC_MDN') {
                            msg = '사용할 수 없는 번호입니다.'
                        }

                        va.$store.dispatch('openAlert', {
                            'message': msg,
                            'va': va,
                            'closeCb': (va) => {
                                if (err == 'NO_TRAN') {
                                    va.$router.push('/join/step01')
                                }
                            }
                        })
                    }, va)
                },
                'va': this
            })*/
        },
        handleError(err) {
            let msg = '서비스 번호 조회 중 오류가 발생하였습니다'
            let subMsg = ''

            if (err == 'NO_TRAN') {
                msg = '인증시간이 만료되었습니다. 다시 인증해주세요.'
            }

            this.$store.dispatch('openAlert', {
                'message': msg,
                'sub_message': subMsg,
                'closeCb': (va) => {
                    if (err == 'NO_TRAN') {
                        va.$router.push('/join/step01')
                    }
                }
                , 'va': this
            })
        },
        doAppendExcludeList(list, cutCount, value) {
            if (!list || !cutCount || !value) {
                return
            }

            if (list.length >= this.excludeLimitCount) {
                list.splice(0, cutCount)
            }

            list.push(value)
        },
        doSearchRecommSvcMdn() {
            let ctx = this
            this.searchRecommSvcMdnFlag = true

            joinApi.getRecommSvcMdn({
                'join_tid': ctx.formData.join_tid,
                "exclude_svc_mdn_list": [] // ctx.recommExcludeSvcMdnList ? ctx.recommExcludeSvcMdnList : []
            }, (recomm_svc_mdn, va) => {
                ctx.searchRecommSvcMdnFlag = false
                ctx.formData.recomm_svc_mdn = recomm_svc_mdn
                // ctx.doAppendExcludeList(ctx.recommExcludeSvcMdnList, 1, recomm_svc_mdn)
            }, (err, va) => {

                ctx.searchRecommSvcMdnFlag = false
                ctx.handleError(err)
            }, this)
        },
        doSearchWishSvcMdn() {
            let ctx = this

            if (!this.checkElement('wish_svc_mdn')) {
                return
            }

            this.selectedWishSvcMdn = ''
            this.searchWishSvcMdnFlag = true
            this.searchedWishSvcMdn = this.formData.wish_svc_mdn

            joinApi.getWishSvcMdnList({
                'join_tid': ctx.formData.join_tid,
                'wish_svc_mdn': this.formData.wish_svc_mdn,
                'exclude_svc_mdn_list': [] // this.wishExcludeMdnList
            }, (wish_svc_mdn_list, va) => {
                ctx.searchWishSvcMdnFlag = false

                if (wish_svc_mdn_list) {
                    ctx.formData.wish_svc_mdn_list = wish_svc_mdn_list

                    /*wish_svc_mdn_list.forEach(item => {
                        ctx.doAppendExcludeList(ctx.wishExcludeMdnList, 1, item)
                    })*/
                }
            }, (err, va) => {
                ctx.searchWishSvcMdnFlag = false
                ctx.handleError(err)
            }, this)
        },
        checkElement(target) {
            let ret = true

            if (target == 'wish_svc_mdn') {
                let numOnlyType = /^[\d+]+$/;

                if (this.formData.wish_svc_mdn.trim().length == 0) {
                    this.updateErrStatus('wish_svc_mdn', true, '희망번호를 입력해 주세요')
                    ret = false
                } else if (!numOnlyType.test(this.formData.wish_svc_mdn)) {
                    this.updateErrStatus('wish_svc_mdn', true, '숫자로만 입력해 주세요')
                    ret = false
                } else if (this.formData.wish_svc_mdn.length != 4) {
                    this.updateErrStatus('wish_svc_mdn', true, '4자리로 입력해 주세요')
                    ret = false
                } else if (this.formData.wish_svc_mdn == this.searchedWishSvcMdn) {
                    this.updateErrStatus('wish_svc_mdn', true, '이미 검색된 번호입니다.')
                    ret = false
                } else {
                    this.updateErrStatus('wish_svc_mdn', false)
                }
            }

            return ret
        },
        updateErrStatus(target, err, errMsg) {
            let status = this.formStatus[target]
            let ref = null

            if (!errMsg) {
                errMsg = ''
            }

            if (status) {
                status.err = err
                status.err_msg = errMsg
                if (err && ref) {
                    ref.focus()
                }
            }
        }
    }
}
</script>

